import React                 from "react"
import {ListBox}             from "src/sections/News/NewsLayout/List/styles";
import {Container, ListItem} from "src/sections/News/NewsLayout/List/styles";
import {Title}               from "src/sections/News/NewsLayout/TextBlock/styles";

export const List = ({data}) => {
  return (
    <Container>
      {data.title && <Title>{data.title}</Title>}
      <ListBox>
        {
          data.items.map((element, i) => (
            <ListItem as="li" key={i}>{element}</ListItem>
          ))
        }
      </ListBox>
    </Container>
  )
};
