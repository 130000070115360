import {Paragraph} from "src/components/Typography";
import styled      from 'styled-components';

export const Container = styled.div`
    margin-bottom: 48px;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        margin-bottom: 36px;
    }
`;

export const ListBox = styled.ul`
    max-width: 1000px;
`;

export const ListItem = styled(Paragraph)`
    position: relative;
    padding-left: 18px;

    &::before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${({theme}) => theme.colors.accentColor};
        position: absolute;
        top: 8px;
        left: 0;
    }

    &:not(:last-child) {
        margin-bottom: 12px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        padding-left: 12px;
        &:before {
            width: 4px;
            height: 4px;
            top: 6px;
        }
    }
`;
